.container {
  display: flex;
  margin-bottom: 50px;
  padding-left: 5%;
  align-items: center;
  background-color: #f3f3f3;
}
.container2 {
  display: flex;
  margin-top: 30px;
  margin-bottom: 50px;
  padding-right: 5%;
  align-items: center;
}
.imageMobile {
  display: none;
  height: auto;
}
.leftside {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 50%;
  font-size: clamp(12px, 1.19vw, 18px);
}
.rightside2 {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 50%;
  font-size: clamp(12px, 1.19vw, 18px);
}
.rightside2 h2 {
  color: #57338e;
  font-size: clamp(25px, 2.65vw, 40px);
  text-align: left;
}
.leftside h2 {
  color: #57338e;
  font-size: clamp(25px, 2.65vw, 40px);
  text-align: left;
}
.rightside {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.leftside2 {
  width: 50%;
  display: flex;
  align-items: center;
}
.leftside2 img {
  width: 90%;
  height: auto;
  border-radius: 32px;
}
.rightside img {
  width: 90%;
  height: auto;
  border-radius: 32px;
}
.link {
  text-decoration: none;
  color: white;
  cursor: pointer;
  background-color: #ff5900;
  border-radius: 9px;
  width: 250px;
  text-align: center;
  padding: 15px 0;
  font-weight: 600;
}
.rightside2 ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media screen and (max-width: 870px) {
  .rightside {
    display: none;
  }
  .leftside2 {
    display: none;
  }
  .rightside2 img {
    display: flex;
    width: 60%;
    border-radius: 20px;
  }
  .leftside img {
    display: flex;
    width: 60%;
    border-radius: 20px;
    margin: 0 auto;
  }
  .leftside {
    font-size: 16px;
    text-align: center;
    width: 90%;
    align-items: center;
    margin: 0 auto;
  }
  .rightside2 {
    font-size: 16px;
    width: 90%;
    text-align: center;
    align-items: center;
    margin: 0 auto;
  }
  .leftside h2 {
    font-size: 30px;
    text-align: center;
  }
  .rightside2 h2 {
    font-size: 30px;
    text-align: center;
  }
  .container {
    padding: 30px 0;
    padding-left: 0;
  }
  .container2 {
    padding-bottom: 30px;
    padding-right: 0;
  }
}
.rightside2 ul {
  align-items: flex-start;
  width: 90%;
  margin: 0 auto;
  text-align: left;
}
@media screen and (max-width: 450px) {
  .leftside img {
    width: 100%;
  }
  .rightside2 img {
    width: 100%;
  }
}
