body {
  font-family: sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
html,
body {
  scroll-behavior: smooth;
}
.MuiSlider-mark {
  color: #ff5900;
  height: 25px !important;
  width: 3px !important;
}
.MuiSlider-markActive {
  background-color: #ff5900 !important;
  height: 25px !important;
  width: 3px !important;
}
.MuiSlider-thumb {
  background-color: transparent !important;
  width: 50px !important;
  height: 30px !important;
  border-radius: 5px !important;
  border: 2px solid #ff5900 !important;
}
.MuiInputBase-root::before {
  border-bottom: none !important;
}
.MuiSlider-markLabel {
  font-size: clamp(16px, 1.19vw, 18px) !important;
  color: #333 !important;
  padding-top: 10px;
}
.MuiSlider-valueLabel {
  background-color: white !important;
  color: #ff5900 !important;
  font-size: clamp(16px, 1.19vw, 18px) !important;
  border: 2px solid #ff5900;
  border-radius: 4px !important;
}
.Mui-focused::after {
  display: none !important;
}
.grecaptcha-badge {
  display: none;
}
