.formTransportator {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 30px 0;
  gap: 20px;
  padding: 30px 10%;
  background-color: #f3f3f3;
}
.row {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
}
.row input {
  font-size: clamp(16px, 1.19vw, 18px);
  width: 33%;
  height: 60px;
  border: solid 2px #333;
  background-color: #fff;
  padding-left: 20px;
}
.row select {
  font-size: clamp(16px, 1.19vw, 18px);
  width: 33%;
  height: 60px;
  border: solid 2px #333;
  background-color: #fff;
  padding-left: 20px;
  cursor: pointer;
}
.row button {
  width: 33%;
}
input:focus {
  outline: none;
}
select:focus {
  outline: none;
}
button {
  background-color: #ff5900;
  height: 57px;
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
  font-size: clamp(14px, 1.19vw, 18px);
}
h2 {
  text-align: center;
  color: #5d358a;
  font-size: clamp(25px, 2.65vw, 40px);
  padding-bottom: 30px;
}
.modal_text {
  font-size: clamp(20px, 2.345vw, 30px) !important;
  color: #5d358a;
  font-weight: bold !important;
}
.wrapper_modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: clamp(200px, 30vw, 400px);
  width: clamp(200px, 30vw, 350px);
  margin: 0 auto;
  text-align: center;
  gap: 30px;
}
.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: clamp(250px, 30vw, 400px);
  height: clamp(250px, 30vw, 400px);
  background-color: white;
  box-shadow: 24px;
  border-radius: 4px;
}
.previous_button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff5900;
  border-radius: 9px;
  border: solid 1px #ff5900;
  width: clamp(125px, 21.5vw, 330px);
  height: 7.683vh;
  font-weight: bold;
  font-size: clamp(14px, 1.125vw, 20px);
  background-color: white;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .row {
    flex-direction: column;
    padding: 0 5%;
  }
  .row input {
    width: 100%;
  }
  .row select {
    width: 100%;
  }
  .row button {
    width: 100%;
  }
}
