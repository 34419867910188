.modal_text {
  font-size: clamp(20px, 2.345vw, 30px) !important;
  color: #5d358a;
  font-weight: bold !important;
}
.wrapper_modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: clamp(200px, 30vw, 400px);
  width: clamp(200px, 30vw, 350px);
  margin: 0 auto;
  text-align: center;
  gap: 30px;
}
.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: clamp(250px, 30vw, 400px);
  height: clamp(250px, 30vw, 400px);
  background-color: white;
  box-shadow: 24px;
  border-radius: 4px;
}
.previous_button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff5900;
  border-radius: 9px;
  border: solid 1px #ff5900;
  width: clamp(125px, 21.5vw, 330px);
  height: 7.683vh;
  font-weight: bold;
  font-size: clamp(14px, 1.125vw, 20px);
  background-color: white;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .containerGoogleMaps {
    height: 1300px;
  }
}
