.video_container {
  position: relative;
  width: 100%;
  height: 80%;
  overflow: hidden;
}

.video_container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 70vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.video_container video {
  width: 100%;
  height: 70vh;
  object-fit: cover;
  z-index: 0;
}

.video_container p {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: clamp(14px, 2.645vw, 40px);
  z-index: 2;
}

.link {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: clamp(12px, 2vw, 30px);
  background-color: #ff5900;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 6px;
  text-align: center;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .link {
    top: 60%;
    padding: 5px 5px;
  }
  .video_container video {
    height: 100%;
  }
  .overlay {
    height: 98% !important;
  }
}

@media screen and (max-width: 347px) {
  .link {
    top: 60%;
    padding: 5px 5px;
    font-size: 11px;
  }
  .video_container p {
    font-size: 11px;
  }
}
