.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 10%;
  gap: 30px;
  background-image: url("/public/images/background-steps.png");
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 500px;
  justify-content: center;
}
.title {
  color: #ff5900;
  font-size: 30px;
  text-align: left;
  padding-bottom: 0;
}
.step {
  color: #ff5900;
  font-weight: bold;
}
.author {
  color: #ff5900;
  font-weight: bold;
  text-align: right;
}
@media screen and (max-width: 768px) {
  .container {
    padding: 30px 5%;
  }
}
