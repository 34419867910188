.icon {
  text-decoration: none;
  position: fixed;
  z-index: 10;
  bottom: 5%;
  right: 0;
  cursor: pointer;
  width: 5%;
}
.icon img {
  width: 90%;
  height: auto;
}
@media screen and (max-width: 768px) {
  .icon {
    width: 15%;
  }
}
