.container_buttons {
  display: flex;
  justify-content: center;
  gap: 30px;
}
.continue_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5900;
  color: white;
  border: none;
  border-radius: 9px;
  width: clamp(125px, 21.5vw, 330px);
  height: 7.683vh;
  font-weight: bold;
  font-size: clamp(14px, 1.125vw, 20px);
  cursor: pointer;
}
.previous_button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff5900;
  border-radius: 9px;
  border: solid 1px #ff5900;
  width: clamp(125px, 21.5vw, 330px);
  height: 7.683vh;
  font-weight: bold;
  font-size: clamp(14px, 1.125vw, 20px);
  background-color: white;
  cursor: pointer;
}
input[type="radio"] {
  display: none;
}
.wrapper_step1 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 2vw;
  text-align: center;
}
.wrapper_labels {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 0 20px;
  background-color: #ff5900;
  color: white;
  font-size: clamp(14px, 1.19vw, 18px);
  border-radius: 9px;
  height: 64px;
  width: clamp(160px, 17vw, 260px);
  cursor: pointer;
}
.label_step1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vw;
}
.label_step1 img {
  width: clamp(160px, 17vw, 260px);
  cursor: pointer;
  height: 253.02px;
  object-fit: contain;
}
.container_step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin: 40px 0;
}
.question_step {
  color: #5d358a;
  font-weight: bold;
  font-size: clamp(30px, 2.645vw, 40px);
  text-align: center;
  width: 60%;
}
.text_step {
  color: #333;
  text-align: center;
  font-size: clamp(16px, 1.19vw, 18px);
  width: clamp(280px, 40vw, 630px);
}
.input_text_step {
  width: clamp(280px, 45.2vw, 690px);
  border: solid 1px #ff5900;
  border-radius: 9px;
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 28px;
  font-size: clamp(16px, 1.19vw, 18px);
  padding-right: 28px;
}
.input_radio_step {
  width: clamp(280px, 45.2vw, 690px);
  height: 64px;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(16px, 1.19vw, 18px);
  background-color: #ff5900;
  color: white;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
}
.progressBar {
  width: 100%;
  height: 10px;
  background-color: white;
  border-bottom: 1px solid #5d358a;
  margin-bottom: 30px;
}

.progressBarInner {
  height: 100%;
  background-color: #5d358a;
  transition: width 0.3s ease;
}
.container_slider {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.input_slider_step {
  color: #5d358a !important;
  height: 10px !important;
  border-radius: 0 !important;
}
.input_slider_number {
  text-align: center;
  width: 100px;
  border: 2px solid #ff5900;
  border-radius: 4px;
  margin: 30px 0;
}
.modal_text {
  font-size: clamp(20px, 2.345vw, 30px) !important;
  color: #5d358a;
  font-weight: bold !important;
}
.wrapper_modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: clamp(200px, 30vw, 400px);
  width: clamp(200px, 30vw, 350px);
  margin: 0 auto;
  text-align: center;
  gap: 30px;
}
.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: clamp(250px, 30vw, 400px);
  height: clamp(250px, 30vw, 400px);
  background-color: white;
  box-shadow: 24px;
  border-radius: 4px;
}
@media screen and (max-width: 768px) {
  .label_step1 {
    flex-basis: calc(50% - 10px);
  }
  .input_text_step {
    padding-left: 5px;
  }
  .question_step {
    width: 100%;
  }
  .label_step1 img {
    height: 170px;
  }
}
